export const LAYER_COLORS = [
    '#d73027',
    '#fc8d59',
    '#fee090',
    '#e0f3f8',
    '#91bfdb',
    '#4575b4'
]

export const DEFAULT_COLOR = [
    '#fff'
]
