// variables
export const NATION_LAYER = 'NATION_LAYER';
export const STATE_LAYER = 'STATE_LAYER';
export const COUNTY_LAYER = 'COUNTY_LAYER';
export const BLOCK_LAYER = 'BLOCK_LAYER';


// Setters
export const SET_VIEWPORT = 'SET_VIEWPORT';
export const SET_BOUNDS = 'SET_BOUNDS';
export const SET_FILTERS = 'SET_FILTERS';
export const SET_SELECTED = 'SET_SELECTED';

/* API CALLS */

export const FETCH_LOCATION_SEARCH_LOADING = 'FETCH_LOCATION_SEARCH_LOADING';
export const FETCH_LOCATION_SEARCH_SUCCESS = 'FETCH_LOCATION_SEARCH_SUCCESS';
export const FETCH_LOCATION_SEARCH_FAILURE = 'FETCH_LOCATION_SEARCH_FAILURE';

export const FETCH_DATA_LAYERS = 'FETCH_DATA_LAYERS';

